import { useQuery } from '@tanstack/react-query';
import React, { createContext, useEffect, useState } from 'react';
import { globalFetchAPI } from '../globalFetch/globalFetchApi';
import { getCookie } from '../utils/helper';

export const GlobalContext = createContext({
  cartProject: [],
  setCartProject: () => [],
  cartCalculationAmount: {},
  setCartCalculationAmount: () => {},
  projectFilter: [],
  setProjectFilter: () => [],
  currentUser: {},
  setCurrentUser: () => {},
  toastMessage: null,
  setToastMessage: () => {},
  accessToken: null,
  setAccessToken: () => '',
  yearFilter: null,
  setYearFilter: () => '',
  initialized: null,
  setInitialized: () => '',
  totalCalculationAmount: {},
  setTotalCalculationAmount: () => {},
  sorting: {},
  setSorting: () => {},
});

const GlobalProvider = ({ children }) => {
  const access_token = getCookie('csrf_access_token');
  const [cartProject, setCartProject] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [accessToken, setAccessToken] = useState(access_token);
  const [yearFilter, setYearFilter] = useState(2022);
  const [initialized, setInitialized] = useState(false);
  const [sorting, setSorting] = useState({ key: 'request_amt', type: 'desc' });
  const [totalCalculationAmount, setTotalCalculationAmount] = useState({
    total: 0,
  });
  const [projectFilter, setProjectFilter] = useState({
    department: [],
    axis: [],
    section: {},
  });
  const [cartCalculationAmount, setCartCalculationAmount] = useState({
    total: 0,
    poss_redu: 0,
    funding: 0,
    total_req_amt: 0,
  });

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { data: userDetailData, refetch } = useQuery(
    ['userDetail'],
    () => globalFetchAPI('user_detail', requestOptions),
    { enabled: false }
  );

  useEffect(() => {
    if (accessToken) {
      refetch();
    }
  }, [accessToken, refetch]);

  useEffect(() => {
    if (userDetailData?.profile) {
      setCurrentUser(userDetailData?.profile);
    }
  }, [userDetailData]);

  return (
    <GlobalContext.Provider
      value={{
        cartProject,
        setCartProject,
        cartCalculationAmount,
        setCartCalculationAmount,
        projectFilter,
        setProjectFilter,
        currentUser,
        setCurrentUser,
        toastMessage,
        setToastMessage,
        accessToken,
        setAccessToken,
        yearFilter,
        setYearFilter,
        initialized,
        setInitialized,
        totalCalculationAmount,
        setTotalCalculationAmount,
        sorting,
        setSorting
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
