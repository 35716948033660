//global fetch API
export const globalFetchAPI = async (url, requestOptions) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    return false;
  }
};
