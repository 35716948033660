import { Checkbox, Col, Typography, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { RightOutlined } from '@ant-design/icons';
import { t } from 'i18next';
const { Title } = Typography;

const DepartmentList = (props) => {
  const { data, sectionFilter, setSectionFilter } = props;
  const [deptArr, setDeptArr] = useState(Object.keys(sectionFilter));
  const [indeterminate, setIndeterminate] = useState({});
  const [activeDept, setActiveDept] = useState();
  const { projectFilter } = useContext(GlobalContext);

  useEffect(() => {
    if (Object.keys(sectionFilter).length) {
      data?.data.forEach((item) => {
        if (sectionFilter?.hasOwnProperty(item?._id)) {
          if (item?.section.length > sectionFilter[item?._id].length) {
            setIndeterminate((prev) => ({ ...prev, [item?._id]: true }));
          } else {
            setIndeterminate((prev) => ({ ...prev, [item?._id]: false }));
          }
        }
      });
    } else {
      setDeptArr([]);
      setActiveDept();
      setIndeterminate({});
    }
  }, [sectionFilter, data]);

  useEffect(() => {
    if (Object.keys(projectFilter?.section).length) {
      setSectionFilter(projectFilter?.section);
    }
    if (projectFilter?.department.length) {
      setActiveDept(projectFilter?.department[0]);
      setDeptArr(projectFilter?.department);
      Object.keys(indeterminate).forEach((item) => {
        !projectFilter?.department.includes(item) && delete indeterminate[item];
      });
    } else {
      setActiveDept();
    }
    // eslint-disable-next-line
  }, [setSectionFilter, projectFilter]);

  const handleSectionOnChange = (e) => {
    if (e.length) {
      const sectionLength = data?.data.find((item) => item?._id === activeDept)
        ?.section?.length;
      if (sectionLength > e.length) {
        setIndeterminate((prev) => ({ ...prev, [activeDept]: true }));
      } else {
        setIndeterminate((prev) => ({ ...prev, [activeDept]: false }));
      }
      if (!deptArr?.includes(activeDept)) {
        setDeptArr((prev) => [...prev, activeDept]);
      }
      setSectionFilter((prev) => ({ ...prev, [activeDept]: e }));
    } else {
      setDeptArr(deptArr?.filter((item) => item !== activeDept));
      delete indeterminate[activeDept];
      delete sectionFilter[activeDept];
    }
  };

  return (
    <Row className='department-list-wrapper'>
      <Col span={12} className='department-content-wrapper'>
        <Title level={5}>{t('dep_name')}</Title>
        <div className='dept-checkbox-group'>
          <Checkbox.Group
            key={deptArr}
            defaultValue={deptArr}
            onChange={(e) => setDeptArr(e)}
          >
            {data?.data?.map((dept, idx) => (
              <div
                className={`sider-checkbox-wrapper ${
                  deptArr?.includes(dept?._id) && 'selected-department'
                }`}
                onClick={() => setActiveDept(dept?._id)}
              >
                <div>
                  <Checkbox
                    value={dept._id}
                    id='test'
                    className='sider-checklist-value'
                    key={idx}
                    indeterminate={indeterminate[dept?._id]}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSectionFilter((prev) => ({
                          ...prev,
                          [e.target.value]: dept?.section.map(
                            (item) => item?.section_name
                          ),
                        }));
                      } else {
                        delete sectionFilter[e.target.value];
                        delete indeterminate[e.target.value];
                      }
                    }}
                  />
                  <span>{dept._id}</span>
                </div>
                <RightOutlined
                  className={`arrow-icon ${
                    dept?._id === activeDept && 'active'
                  } `}
                />
              </div>
            ))}
          </Checkbox.Group>
        </div>
      </Col>
      <Col span={12} className='department-section-content-wrapper'>
        <Title level={5}>{t('section_name')}</Title>
        <div className='section-checkbox-group'>
          <Checkbox.Group
            onChange={(e) => {
              handleSectionOnChange(e);
            }}
            key={sectionFilter?.[activeDept]}
            defaultValue={sectionFilter?.[activeDept]}
          >
            {activeDept &&
              data.data
                .find((obj) => obj._id === activeDept)
                .section.map((section, idx) => (
                  <Checkbox
                    value={section.section_name}
                    className='sider-checklist-value'
                    key={idx}
                    checked={true}
                  >
                    {section.section_name}
                  </Checkbox>
                ))}
          </Checkbox.Group>
        </div>
      </Col>
    </Row>
  );
};

export default DepartmentList;
