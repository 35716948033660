import { useMutation } from '@tanstack/react-query';
import { Button, Card, Form, Input, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../common/layout/header';
import { GlobalContext } from '../../context/GlobalContext';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import { getCookie } from '../../utils/helper';

const LoginPage = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const { currentUser, setToastMessage, setAccessToken } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...formValues }),
  };
  const mutation = useMutation(
    () => globalFetchAPI('user_login', requestOptions),
    {
      onSuccess(data) {
        if (data?.status_code === 200) {
          const access_token = getCookie('csrf_access_token');
          setAccessToken(access_token);
          navigate('/projects');
        } else {
          setToastMessage(t(data?.msg));
        }
      },
    }
  );

  useEffect(() => {
    if (currentUser) {
      navigate('/projects');
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const loginHandler = async (e) => {
    if (Object.keys(formValues).length) {
      mutation.mutateAsync();
    }
  };

  return (
    <>
      <PageHeader />
      <div className='login-wrapper'>
        <Card className='login-form-container' title={t('signin')}>
          <Form onFinish={(e) => loginHandler(e)} autoComplete='off'>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: t('not_valid_email'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('email_address')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[{ required: true, message: t('user_password_input') }]}
            >
              <Input.Password
                className='login-input'
                placeholder={t('user_password')}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
              />
            </Form.Item>

            <Form.Item>
              <Space className='login-footer-btn-wrapper'>
                <Button type='primary' htmlType='submit'>
                  {t('signin')}
                </Button>
                <Typography onClick={() => navigate('/register')}>
                  {t('signup')}
                </Typography>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
