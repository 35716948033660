import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Radio } from 'antd';
import AxisTagsList from './AxisTagsList';
import DepartmentList from './DepartmentList';
import { GlobalContext } from '../../../context/GlobalContext';
import { useTranslation } from 'react-i18next';

const TableModal = (props) => {
  const { open, setOpen, currentTab, setCurrentTab, modelData } = props;
  const [depList, setDepList] = useState([]);
  const [axisList, setAxisList] = useState([]);
  const [axisFilter, setAxisFilter] = useState([]);
  const [sectionFilter, setSectionFilter] = useState({});
  const { t } = useTranslation();
  const { setProjectFilter, projectFilter } = useContext(GlobalContext);

  useEffect(() => {
    setAxisFilter(axisFilter);
    if (!open) {
      Object.keys(projectFilter?.section).length
        ? setSectionFilter(projectFilter?.section)
        : setSectionFilter({});
      projectFilter?.axis.length
        ? setAxisFilter(projectFilter?.axis)
        : setAxisFilter([]);
    }
    // eslint-disable-next-line
  }, [open, projectFilter, currentTab]);

  useEffect(() => {
    modelData.forEach((item) => {
      if (item?.title === 'dep_search') {
        setDepList(item);
      } else {
        setAxisList(item);
      }
    });
  }, [modelData]);

  return (
    <>
      <Modal
        className='modal-wrapper'
        title={[
          <Radio.Group
            className='modal-title-wrapper'
            defaultValue={currentTab}
            key={currentTab}
            onChange={(e) => setCurrentTab(e.target.value)}
          >
            <Radio.Button value='dep_search'>{t('dep_search')}</Radio.Button>
            <Radio.Button value='axis_search'>{t('axis_search')}</Radio.Button>
          </Radio.Group>,
        ]}
        width={920}
        visible={open}
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setProjectFilter((prev) => ({
                ...prev,
                axis: axisFilter,
                department: Object.keys(sectionFilter),
                section: sectionFilter,
              }));
              setOpen(false);
            }}
          >
            {t('filter')}
          </Button>,
        ]}
      >
        {currentTab === 'axis_search' ? (
          <AxisTagsList
            data={axisList}
            axisFilter={axisFilter}
            setAxisFilter={setAxisFilter}
          />
        ) : (
          <DepartmentList
            data={depList}
            sectionFilter={sectionFilter}
            setSectionFilter={setSectionFilter}
          />
        )}
      </Modal>
    </>
  );
};

export default TableModal;
