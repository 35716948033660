import axisCalculation from '../data/axisCalculation.json';
import { REDUCT_GRANT_FLAG } from '../pages/projectList/constants/Constants';

export const FilterCart = (setCart, value) => {
  setCart((prev) =>
    prev.find((obj) => obj?.id === value?.id)
      ? prev.filter((item) => item?.id !== value?.id)
      : [...prev, value]
  );
};

export const shortAmount = (value) => {
  return new Intl.NumberFormat('ja-JP', {
    notation: 'compact',
  }).format(value || 0);
};

export const longAmount = (value) => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(value || 0);
};

export const numberFormat = (value, isFromCalculation) => {
  if (value) {
    return new Intl.NumberFormat('ja-JP').format(value);
  }
  return isFromCalculation ? 0 : null;
};

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const handleCalculation = (projectList) => {
  const amountCalculatedList = projectList.map((project) => {
    let totalReductionPercentage = 0;
    let totalGrantPercentage = 0;
    axisCalculation?.forEach((item) => {
      if (
        project?.axis.includes(item?.axis) &&
        item?.reduct_grant_flag === REDUCT_GRANT_FLAG.reduction
      ) {
        totalReductionPercentage += +item?.percent;
      }
      if (
        project?.axis.includes(item?.axis) &&
        item?.reduct_grant_flag === REDUCT_GRANT_FLAG.grant
      ) {
        totalGrantPercentage += +item?.percent;
      }
    });
    totalReductionPercentage = totalReductionPercentage > 1 ? 1 : totalReductionPercentage;
    totalGrantPercentage = totalGrantPercentage > 1 ? 1 : totalGrantPercentage;
    return {
      ...project,
      poss_reduction_amt: project?.request_amt * totalReductionPercentage,
      poss_grant_amt: project?.request_amt * totalGrantPercentage,
      assess_amt: project?.request_amt * totalReductionPercentage,
    };
  });
  return amountCalculatedList;
};
