import { Checkbox, Col, Row } from 'antd';
import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';

const AxisTagsList = (props) => {
  const { data, axisFilter, setAxisFilter } = props;
  const { projectFilter } = useContext(GlobalContext);

  useEffect(() => {
    if (projectFilter?.axis.length) {
      setAxisFilter(projectFilter?.axis);
    } else {
      setAxisFilter([]);
    }
  }, [setAxisFilter, projectFilter]);

  return (
    <div className='axis-checkbox-group'>
      <Checkbox.Group
        onChange={(e) => {
          setAxisFilter(e);
        }}
        key={axisFilter}
        defaultValue={axisFilter}
      >
        <Row className='axis-tags-list-wrapper'>
          {data?.data.map((data, idx) => (
            <Col span={6} key={idx} className='axis-tags-content-wrapper'>
              <Checkbox value={data._id} className='sider-checklist-value'>
                {data._id}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default AxisTagsList;
